import React, { useEffect, useState } from "react";
import * as S from "./LandingPage.styles";
import { LoremIpsum } from "./ladningPageLorem";
import { Link, useNavigate, useParams } from "react-router-dom";

const LadningPage = () => {
  const { id: openedModalID } = useParams();
  const naviagate = useNavigate();
  const [isSliderLoaded, setIsSliderLoaded] = useState(false);
  const width = window.innerWidth;

  const tiles = [
    {
      name: <S.TileHeader>GB Akwarystyka</S.TileHeader>,
      id: 1,
      description: (
        <S.DescriptionText>
          GB Akwarystyka to wyjątkowy sklep akwarystyczny zlokalizowany w
          Bystrzycy, blisko Oławy i Wrocławia. Specjalizujemy się w hodowli i
          sprzedaży różnorodnych gatunków ryb akwariowych oraz roślin,
          zapewniając najwyższą jakość i profesjonalne podejście do akwarystyki.{" "}
          <br />
          Naszą dumą są majestatyczne dyskowce – oferujemy szeroką gamę odmian
          barwnych, które zachwycą każdego miłośnika akwarystyki. W naszym
          asortymencie znajdziesz również energetyczne pyszczaki, idealne do
          akwariów biotopowych inspirowanych Jeziorem Malawi. Ponadto posiadamy
          bogaty wybór innych gatunków ryb słodkowodnych, w tym popularnych ryb
          ławicowych, ryb denne oraz bardziej egzotycznych okazów. <br />
          Dbamy także o różnorodność w akwarystyce, dlatego w ofercie znajdziesz
          ślimaki akwariowe, które nie tylko ozdobią zbiornik, ale również
          pomogą w utrzymaniu jego czystości. <br />
          W GB Akwarystyka stawiamy na kompleksowe wsparcie naszych klientów –
          od doboru odpowiednich gatunków, po profesjonalne porady dotyczące
          pielęgnacji i żywienia. Zawsze służymy pomocą! <br />
          Zapraszamy do odwiedzenia naszego sklepu w Bystrzycy, gdzie znajdziesz
          wszystko, czego potrzebujesz, by stworzyć piękny, zdrowy i tętniący
          życiem podwodny świat. 🐠🌿 <br />
        </S.DescriptionText>
      ),
      images: [],
      tileImage: "tiles1",
      modalName: <S.ModalHeader>GB Akwarystyka</S.ModalHeader>,
    },
    {
      id: 9,
      name: <S.TileHeader>🎉 Promocja! 🎉</S.TileHeader>,
      modalName: (
        <S.ModalHeader>🎉 Promocja do wyczerpania zapasów! 🎉</S.ModalHeader>
      ),
      description: (
        <>
          <S.DescriptionText>
            Od dzisiaj (15.02.2025) do wyczerpania zapasów wyjątkowa oferta na
            ryby akwarystyczne:
          </S.DescriptionText>
          <S.DescriptionTextWithMarginWithouCenter>
            <ul>
              <li>
                Pyszczaki Malawi (Red-Red, Soulosi, Yellow) – tylko 6 zł/szt.
                przy minimalnym zakupie 5 sztuk z danego gatunku.
              </li>
              <li>
                Gupiki (Czerwony oraz Albino Mix) – tylko 4 zł/szt. przy
                minimalnym zakupie 5 sztuk.
              </li>
            </ul>
          </S.DescriptionTextWithMarginWithouCenter>
          <S.DescriptionTextWithMargin>
            Zapraszamy do naszego sklepu! Skorzystaj z okazji, zanim ryby znikną
            z naszych akwariów!
          </S.DescriptionTextWithMargin>
          <S.DescriptionTextWithMargin>
            📍 Odwiedź nas w sklepie po umówionej wizycie - 📞{" "}
            <strong>+48 698 954 898!</strong> <br />
            Godziny otwarcia: Poniedziałek - Sobota: 10:00 - 20:00 / Niedziela:
            15:00 - 20:00! <br />
          </S.DescriptionTextWithMargin>
        </>
      ),
      images: [
        "malawi_1",
        "malawi_2",
        "red-red",
        "malawi_4",
        "malawi_5",
        "gupik_4",
      ],
      tileImage: "gupik_promocja",
      customHeight: "300px",
    },
    {
      id: 10,
      name: <S.TileHeader>💣 Wyprzedaż akwariów! 💣</S.TileHeader>,
      modalName: <S.ModalHeader>🎉 Wyjątkowa okazja 🎉</S.ModalHeader>,
      description: (
        <>
          <S.DescriptionText>
            Na sprzedaż posiadamy 16 sztuk gotowych zestawów które służyły w
            naszym sklepie.
          </S.DescriptionText>
          <S.DescriptionTextWithMargin>
            Akwaria używane o wymiarach 30x30x35 (30l) diversa. Akwaria z
            pokrywą szklaną, oklejone z trzech stron i ocieplone. W zestawie
            grzałka z termostatem 25 wat, podwójny filtr gąbkowy NSF-200L Aqua
            Nova. Liczba ograniczona! Aktualnie dostępnych jest 16 sztuk - cena
            za sztukę 99zł, cena promocyjna przy kupnie całości - 1200 zł.
          </S.DescriptionTextWithMargin>
          <S.DescriptionTextWithMargin>
            📍 Odwiedź nas w sklepie po umówionej wizycie - 📞{" "}
            <strong>+48 698 954 898!</strong> <br />
            Godziny otwarcia: Poniedziałek - Sobota: 10:00 - 20:00 / Niedziela:
            15:00 - 20:00! <br />
          </S.DescriptionTextWithMargin>
        </>
      ),
      images: [
        "akwaria2",
        "akwaria3",
        "akwaria4",
        "akwaria5",
        "akwaria6",
        "akwaria7",
        "akwaria8",
      ],
      tileImage: "akwaria",
      customHeight: "300px",
    },
  ];

  const [newsModalOpen, setNewsModalOpen] = useState(
    openedModalID ? Number(openedModalID) : -1
  );

  useEffect(() => {
    setNewsModalOpen(openedModalID ? Number(openedModalID) : -1);
  }, [openedModalID]);

  return (
    <S.MainPage>
      {width > 768 ? (
        <S.Slider>
          <S.Image
            onLoad={() => setIsSliderLoaded(true)}
            src={`${process.env.PUBLIC_URL}/assets/${
              isSliderLoaded ? "slider" : "lowSlider"
            }.webp`}
            loading="lazy"
          />
        </S.Slider>
      ) : null}
      <S.InformationSection>
        <S.InfomrationContentContainer>
          {width < 768 ? <S.MobileHeader>Aktualności:</S.MobileHeader> : null}
          <S.RowContainer>
            {tiles.map(({ name, tileImage }, index) => (
              <S.Tiles
                onClick={() => {
                  setNewsModalOpen(index);
                  naviagate(`/informacja/${index}`);
                }}
                key={tileImage + index}
              >
                <S.TileUpperContainer>
                  <S.TilesPhoto
                    src={`${process.env.PUBLIC_URL}/temporaryAssets/${tileImage}.webp`}
                  />
                </S.TileUpperContainer>
                <S.TileInfo>{name}</S.TileInfo>
              </S.Tiles>
            ))}
          </S.RowContainer>
          {/* {width < 768 ? (
            <div>
              <S.FooterHeader>
                Realizacja:{" "}
                <a target="_blank" href="https://www.gwmdigital.pl/">
                  <S.FooterSpan>gwmdigital.pl</S.FooterSpan>
                </a>
              </S.FooterHeader>
            </div>
          ) : null} */}
        </S.InfomrationContentContainer>
      </S.InformationSection>
      {newsModalOpen !== -1 ? (
        <>
          <S.NewsModal>
            <S.ReverseButton
              onClick={() => {
                setNewsModalOpen(-1);
                naviagate(`/`);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="1em"
                height="1em"
              >
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="m15.547 2-1.305 1.27L6 11.293v1.414l8.242 8.022L15.547 22H17v-1.414l-1.305-1.271L8.18 12l7.515-7.316L17 3.414V2z"
                ></path>
              </svg>
            </S.ReverseButton>
            <S.PostImage
              src={`${process.env.PUBLIC_URL}/temporaryAssets/${tiles[newsModalOpen].tileImage}.webp`}
            />
            <S.Title>{tiles[newsModalOpen].modalName}</S.Title>
            {tiles[newsModalOpen].description}
            {tiles[newsModalOpen].images !== undefined &&
            tiles[newsModalOpen].images.length > 0 ? (
              <S.ImagesRow>
                {tiles[newsModalOpen].images.map((string) => (
                  <S.ModalImageContainer custom={30}>
                    <S.ModalImage
                      loading="lazy"
                      key={string}
                      src={`${process.env.PUBLIC_URL}/temporaryAssets/${string}.webp`}
                    />
                  </S.ModalImageContainer>
                ))}
              </S.ImagesRow>
            ) : null}
          </S.NewsModal>
          <S.ModalOverlay
            onClick={() => {
              setNewsModalOpen(-1);
              naviagate(`/`);
            }}
          />
        </>
      ) : null}
      {width < 768 ? null : (
        <S.Footer>
          <div>
            <a
              href={"https://www.facebook.com/profile.php?id=61564302763635"}
              target="_blank"
            >
              <S.SocialMediaIcon
                src={`${process.env.PUBLIC_URL}/assets/facebook.svg`}
                alt="facebook"
              />
            </a>
          </div>
          <div>
            <S.FooterHeader>
              Realizacja:{" "}
              <a target="_blank" href="https://www.gwmdigital.pl/">
                <S.FooterSpan>gwmdigital.pl</S.FooterSpan>
              </a>
            </S.FooterHeader>
          </div>
        </S.Footer>
      )}
    </S.MainPage>
  );
};

export default LadningPage;
