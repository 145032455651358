import styled, { keyframes } from "styled-components";

const shake = keyframes`
  0% { transform: rotate(0deg); }
  10% { transform: rotate(-4deg); }
  20% { transform:  rotate(-8deg); }
  30% { transform: rotate(-12deg); }
  40% { transform: rotate(-8deg); }
  50% { transform:  rotate(-4deg); }
  60% { transform:  rotate(0deg); }
  70% { transform:  rotate(4deg); }
  80% { transform:  rotate(8deg); }
  90% { transform:  rotate(12deg); }
  100% { transform:  rotate(16deg); }
`;

export const MainPage = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 90px);
  width: 100%;
`;

export const FooterHeader = styled.h6`
  margin-bottom: 0px;

  @media (max-width: 768px) {
    margin: 0px;
    font-weight: normal;
    color: #fff;
  }
`;

export const FooterSpan = styled.span`
  color: rgb(240 156 79);
`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 0;
  color: #000;
  -webkit-box-shadow: 0px -10px 15px -9px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px -10px 15px -9px rgba(66, 68, 90, 1);
  box-shadow: 0px -10px 15px -9px rgba(66, 68, 90, 1);

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const SocialMediaIcon = styled.img`
  width: 30px;
  height: 30px;

  &&:hover {
    animation: ${shake} 0.5s;
  }
`;

export const Slider = styled.div`
  height: 45vh;
  position: relative;
`;

export const ReverseButton = styled.div`
  position: absolute;
  top: 3%;
  left: 1%;
`;

export const ShopLink = styled.div`
  top: 50%;
  left: 50%;
  position: absolute;
  color: #fff;
  transform: translate(-50%, -50%);
  cursor: pointer;

  &&:hover {
    color: #a0e1ef;
    transition: 1s;
  }
`;

export const ShopHeader = styled.h2`
  font-size: 3.5rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 50% 70%;

  @media (max-width: 1100px) {
    aspect-ratio: 2 / 1;
  }

  @media (max-width: 768px) {
    aspect-ratio: 2 / 1;
  }
`;

export const InformationSection = styled.div`
  background-color: #eee;
  min-height: 55vh;
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  padding-top: 50px;
  justify-content: center;
  height: -moz-available; /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  height: fill-available;

  @media (max-width: 768px) {
    height: auto;
    background: url(${process.env.PUBLIC_URL}/assets/new.webp);
    /* min-height: 95vh; */
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
`;

export const ModalOverlay = styled.div`
  background-color: #000000bd;
  z-index: 4;
  position: fixed;
  top: 0%;
  left: 0;
  height: 100vh;
  width: 100vw;
`;

export const NewsModal = styled.div`
  position: fixed;
  min-height: 75vh;
  z-index: 5;
  min-width: 75vw;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  background-color: #fff;
  border: 0.5px solid #eee;
  border-radius: 20px;
  overflow: auto;
  padding: 10px 40px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PostImage = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 20px 20px 0 0;
  max-height: 30vh;
`;

export const AdditionalInfo = styled.span`
  color: rgb(240 156 79);
  cursor: pointer;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

export const DescriptionText = styled.p`
  line-height: 2rem;
  text-align: center;
`;

export const TileHeader = styled.h5`
  font-weight: normal;
  font-size: 20px;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const ModalHeader = styled.h2``;

export const TextElement = styled.span<{ textColor: string }>`
  color: ${({ textColor }) => textColor};
`;

export const DescriptionTextWithMargin = styled.p`
  line-height: 2rem;
  text-align: center;
  margin-top: 2rem;
`;

export const DescriptionTextWithMarginWithouCenter = styled.p`
  line-height: 2rem;
  margin-top: 2rem;
`;

export const ListRow = styled.ul`
  display: flex;
  flex-direction: row;
  gap: 15px;
  flex-wrap: wrap;
`;

export const ImagesRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  gap: 5%;
  row-gap: 15px;

  @media (max-width: 1200px) {
    gap: 10%;
    row-gap: 15px;
  }

  @media (max-width: 768px) {
    gap: 15px;
    flex-direction: column;
    align-items: center;
  }
`;

export const ModalImageContainer = styled.div<{
  custom: number;
}>`
  width: ${({ custom }) => custom}%;
  aspect-ratio: 1.5 / 1;

  @media (max-width: 1200px) {
    width: 45%;
    height: auto;
  }

  @media (max-width: 768px) {
    width: 95%;
    height: auto;
  }
`;

export const ModalImage = styled.img<{ customHeight?: string }>`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const InfomrationContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 40px;
  /* margin: 50px 120px; */
  /* justify-content: center; */
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
  align-items: center;
  @media (max-width: 768px) {
    margin: 0 20px;
  }
`;

export const MobileHeader = styled.h3`
  color: #fff;
  margin: 0;
  font-size: 2rem;
`;

export const Typography = styled.p`
  padding: 0;
  margin: 0;
  font-size: 60px;
  color: green;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3.5%;
  row-gap: 2rem;
  /* padding: 0 40px; */
  box-sizing: border-box;
  /* justify-content: center; */
  flex-grow: 0;
  width: 100%;
  flex-shrink: 0;
  flex-wrap: wrap;
  margin: auto;
  padding: 20px;

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    gap: 2%;
    row-gap: 15px;
  }

  @media (max-width: 1350px) {
    justify-content: center;
    padding: 40px;
  }
`;

export const TilesPhoto = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 15px 15px 0px 0px;
`;

export const Tiles = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background-color: #fff;
  width: 22%;
  height: 270px;
  -webkit-box-shadow: 8px 8px 7px 0px rgba(143, 143, 143, 1);
  -moz-box-shadow: 8px 8px 7px 0px rgba(143, 143, 143, 1);
  box-shadow: 8px 8px 7px 0px rgba(143, 143, 143, 1);

  &&:hover {
    opacity: 50%;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    width: 49%;
    -webkit-box-shadow: 8px 5px 7px -4px rgba(143, 143, 143, 1);
    -moz-box-shadow: 8px 5px 7px -4px rgba(143, 143, 143, 1);
    box-shadow: 8px 5px 7px -4px rgba(143, 143, 143, 1);
  }
`;

export const TileInfo = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: -moz-available; /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  height: fill-available;
  max-height: 50%;
  font-size: 20px;
  padding: 0 15px;
`;

export const TileUpperContainer = styled.div`
  height: 50%;
`;
