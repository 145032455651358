import styled, { css } from "styled-components";

export const ShopContainer = styled.div<{ $isOnDetailsPage: boolean }>`
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "wdth" 100;
  height: calc(100vh - 110px);
  max-height: 100vh;
  box-sizing: border-box;
  overflow: auto;
  width: 100vw;
  color: #000;
  position: relative;
  background: url(${process.env.PUBLIC_URL}/assets/new.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;

  @media (max-width: 768px) {
    max-height: unset;
    height: unset;
    /* min-height: -moz-available;
    min-height: -webkit-fill-available;
    min-height: fill-available; */
    min-height: calc(100vh - 110px);
    ::-webkit-scrollbar {
      display: none;
    }
  }

  ${({ $isOnDetailsPage }) =>
    $isOnDetailsPage &&
    css`
      @media (max-width: 768px) {
        background-color: unset;
        box-sizing: border-box;
      }
    `}
`;

export const BackgroundDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  /* height: calc(100vh - 110px); */
  min-height: -moz-available;
  min-height: -webkit-fill-available;
  min-height: fill-available;
  box-sizing: border-box;
  background: url(${process.env.PUBLIC_URL}/assets/new.png);

  @media (max-width: 768px) {
    /* background-color: unset; */
    max-height: unset;
    background-size: cover;
  }
`;

export const ShopPageCenterBox = styled.div`
  padding: 50px 10%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  /* justify-content: space-between; */
  gap: 30px;
  position: relative;
  min-height: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 0 0 0 0;
  }
`;

export const AdditionalContainer = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
`;

export const AdditionalSetting = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  gap: 5px;
  align-self: flex-end;
  height: 100%;
`;

export const AdditionalImageContainer = styled.div`
  height: 24px;
  width: 24px;
  box-sizing: border-box;
  cursor: pointer;
`;

export const InputFilter = styled.input`
  border: unset;
  width: 90%;
  height: 100%;
  box-sizing: border-box;
  font-size: 19px;

  &&:focus-visible {
    outline: unset;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-end;
  padding: 10px 20px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 22px;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 10px;
    box-sizing: border-box;
  }
`;

export const ImageContainer = styled.div`
  width: 25px;
  box-sizing: border-box;
  height: 25px;
`;

export const AdminManagmentPannel = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  background-color: #fff;
  box-sizing: border-box;
  align-items: center;
`;

export const AdminTabs = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px 20px;
  cursor: pointer;

  &&:hover {
    background-color: #a0e1ef;
  }
`;

export const StylingBox = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  gap: 5%;
  height: 100%;
  /* justify-content: center; */

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ProductTile = styled.div<{ $isSelectedTileMode: boolean }>`
  width: calc(25% - 40px);
  height: 200px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  border: 1px solid #eee;
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 100%;
    height: 150px;
    padding: 0;
    border-radius: 4px;
    flex-direction: row;
    border: unset;
  }
`;

export const RightMobileSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  box-sizing: border-box;
  justify-content: flex-start;
  height: 100%;
`;

export const TileHeaderName = styled.p`
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const TileMobileHeaderName = styled.p`
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const IconTileContainer = styled.div`
  width: 80%;
  height: 80%;

  @media (max-width: 768px) {
    height: 100%;
    width: 40%;
  }
`;

export const TileProductImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const LeftMenu = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-sizing: border-box;
  width: 25%;
  align-items: center;
  gap: 30px;
  height: fit-content;
  font-size: 20px;
  padding: 20px 0;
  max-height: 650px;
  overflow: auto;

  /* @media (max-width: 768px) {
    flex-direction: row;
    width: 100%;
    overflow: auto;
    box-sizing: border-box;
    width: 98%;
    overflow: auto;
  } */
`;

export const SelectCategory = styled.div`
  display: flex;
  width: 50%;
  padding: 15px;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-around;
  background-color: #fff;
  border-radius: 22px;
  align-items: center;

  &&:hover {
    background-color: #a0e1ef;
    transition: 0.5s;
  }
`;

export const ArrowDownIcon = styled.div`
  width: 20px;
  height: 20px;
`;

export const Tab = styled.div<{ isSelected: boolean }>`
  padding: 10px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${({ isSelected }) =>
    isSelected ? "rgb(240 156 79)" : "unset"};

  &&:hover {
    background-color: ${({ isSelected }) =>
      isSelected ? "rgb(240 156 79)" : "aliceblue"};
  }
`;

export const ContentContainer = styled.div<{ $isSelectedTileMode: boolean }>`
  display: flex;
  flex-direction: ${({ $isSelectedTileMode }) =>
    $isSelectedTileMode ? "row" : "column"};
  /* background-color: #fff; */
  width: -webkit-fill-available;
  width: -moz-available;
  gap: 15px;
  height: fit-content;
  max-height: 700px;
  overflow: auto;
  flex-wrap: ${({ $isSelectedTileMode }) =>
    $isSelectedTileMode ? "wrap" : "no-wrap"};

  @media (max-width: 768px) {
    flex-direction: row;
    /* gap: 15px; */
    padding: 40px;
    flex-wrap: wrap;
    max-height: unset;
    overflow: unset;
    justify-content: space-around;
  }
`;

export const FishRow = styled.div<{ isHidden: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 0.5px solid #eee;
  height: 100px;
  padding: 10px 20px;
  opacity: ${({ isHidden }) => (isHidden ? "30%" : "100%")};
  background-color: #fff;
  border-radius: 6px;
  cursor: pointer;
`;

export const FishPhoto = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
`;

export const LeftRowSide = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

export const NameAndStatusColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const AdminPannelRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 14px;
  cursor: pointer;
`;

export const StatusRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 10px;
`;

export const FishColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StatusContainer = styled.div<{ backgroundColor: string }>`
  width: 15px;
  height: 15px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 100%;
`;

export const LeftItemSection = styled.div`
  background-color: #fff;
  padding: 5px;
  /* min-height: 300px; */

  @media (max-width: 768px) {
    padding: 0;
    /* height: 150px; */
    width: 100%;
    height: 100%;
    margin: 30px 0 0 0;
    background-color: unset;
  }
`;

export const MoreDetilsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  align-items: flex-start;
  gap: 50px;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 0px;
  }
`;

export const Return = styled.div`
  cursor: pointer;
  left: 10%;
  top: 25px;
  position: absolute;
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 22px;

  @media (max-width: 768px) {
    left: 5px;
    top: 15px;
    overflow: auto;
  }
`;

export const ItemImage = styled.img`
  height: 400px;
  width: 400px;
  object-fit: cover;
  aspect-ratio: 1/1;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

export const ZoomedImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const ModalOverlay = styled.div`
  background-color: #000000bd;
  z-index: 4;
  position: fixed;
  top: 0%;
  left: 0;
  height: 100vh;
  width: 100vw;
`;

export const RightItemSection = styled.div`
  width: -webkit-fill-available;
  width: -moz-available;
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 70px;
  position: relative;
  height: fit-content;

  @media (max-width: 768px) {
    margin-top: -10px;
    /* border-radius: 15px 15px 0 0; */
  }
`;

export const Footer = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 15px 25px;
  background-color: #fff;
  align-self: flex-end;
`;

export const EditContainer = styled.div`
  top: 10%;
  right: 5%;
  position: absolute;
  cursor: pointer;
`;

export const PhotoModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  width: 80vw;
  height: 80vh;
  z-index: 5;
  padding: 40px;
  background-color: #ffffff59;
`;

export const TopInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Input = styled.input`
  width: 200px;
  border-radius: 20px;
  border: 1px solid #eee;
  padding: 10px 20px;
`;

export const Text = styled.p<{ size: string; weight: string }>`
  font-size: ${({ size }) => size};
  font-weight: ${({ weight }) => weight};
  line-height: 2rem;
`;

export const DangerousText = styled.div``;

export const TextArea = styled.textarea`
  width: 500px;
  border-radius: 20px;
  border: 1px solid #eee;
  padding: 10px 20px;
`;
